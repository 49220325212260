import React from "react";
import Breadcrumb from "../layout/Breadcrumb";
import UserList from "../components/Users/UserList";
import NotesList from "../components/Notes/NotesList";

export default () => (
  <>
    <Breadcrumb breadcrumb="Notes">
      <div className="dropdown">
        <button
          className="btn btn-round"
          role="button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="material-icons">settings</i>
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <a
            className="dropdown-item"
            href="#"
            data-toggle="modal"
            data-target="#team-manage-modal"
          >
            Edit Team
          </a>
          <a className="dropdown-item" href="#">
            Share
          </a>
          <div className="dropdown-divider" />
          <a className="dropdown-item text-danger" href="#">
            Leave
          </a>
        </div>
      </div>
    </Breadcrumb>

    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11 col-xl-10">
          <div className="page-header">
            <h1>Notes</h1>
            <p className="lead">
              Take notes in class and organise them here for later.
            </p>

            <UserList />
          </div>

          <hr />

          <NotesList />
        </div>
      </div>
    </div>
  </>
);
