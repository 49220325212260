import React, { Component } from "react";
import Navigation from "./layout/Navigation.js";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Account from "./pages/Account.js";
import Archive from "./pages/Archive.js";
import Assignments from "./pages/Assignments.js";
import Calendar from "./pages/Calendar.js";
import Changelog from "./pages/Changelog.js";
import Home from "./pages/Home.js";
import Notes from "./pages/Notes.js";
import Subject from "./pages/Subject.js";
import Today from "./pages/Today.js";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="layout layout-nav-side">
          <Navigation />

          <div className="main-container">
            <Route exact path="/" component={Home} />
            <Route path="/account" component={Account} />
            <Route path="/archive" component={Archive} />
            <Route path="/assignments" component={Assignments} />
            <Route path="/calendar" component={Calendar} />
            <Route path="/changelog" component={Changelog} />
            <Route path="/notes" component={Notes} />
            <Route path="/subject" component={Subject} />
            <Route path="/today" component={Today} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
