import React from "react";

export default ({ title, updated, children }) => (
  <div className="card card-note">
    <div className="card-header">
      <div className="media align-items-center">
        {children}
        <div className="media-body">
          <h6 className="mb-0">
            {title}
          </h6>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <span>{updated}</span>
        <div className="ml-1 dropdown card-options">
          <button
            className="btn-options"
            type="button"
            id="note-dropdown-button-1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              Edit
            </a>
            <a className="dropdown-item text-danger" href="#">
              Delete
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body">
      <p>
        Here&#39;s a quick rundown of companies the client expressed interest in
        on our call this morning:
      </p>
      <ul>
        <li>
          <a href="#">Commonwealth Bank of Australia</a> for the bright,
          positive color scheme
        </li>
        <li>
          <a href="#">Bupa Health Insurance</a> for the adaptability of their
          logo around the site&#39;s layout
        </li>
        <li>
          <a href="#">OPSM</a> again for the color scheme, this time for the
          softer pallette
        </li>
      </ul>
    </div>
  </div>
);
