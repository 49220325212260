import React from "react";
import NavItem from "./NavItem";
import { user } from "../api/users";
import subjects from "../api/subjects";
import { Link } from "react-router-dom";
import logo from "../images/studious.svg";

export default () => (
  <div className="navbar navbar-expand-lg bg-dark navbar-dark sticky-top">
    <Link to="/" className="navbar-brand">
      <img alt="Studious" src={logo} className="img-fluid" />
    </Link>

    <div className="d-flex align-items-center">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-collapse"
        aria-controls="navbar-collapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="d-block d-lg-none ml-2">
        <div className="dropdown">
          <a
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img alt="Image" src={user.avatar} className="avatar" />
          </a>

          <div className="dropdown-menu dropdown-menu-right">
            <a href="/account" className="dropdown-item">
              Account
            </a>
            <a href="#" className="dropdown-item">
              Log Out
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="collapse navbar-collapse flex-column" id="navbar-collapse">
      <ul className="navbar-nav d-lg-block">
        <NavItem to="/today">Today</NavItem>

        {subjects.map(subject =>
          <NavItem to="/subject">{subject.code}</NavItem>)
        }

        <li className="nav-item">
          <a className="nav-link" href="/notes">
            Notes
          </a>
        </li>

        <li className="nav-item">
          <a className="nav-link" href="/assignments">
            Assignments
          </a>
        </li>

        <li className="nav-item">
          <Link to="/subject" className="nav-link">
            Subject
          </Link>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            href="#"
            data-toggle="collapse"
            aria-expanded="false"
            data-target="#submenu-2"
            aria-controls="submenu-2"
          >
            Subjects
          </a>

          <div id="submenu-2" className="collapse">
            <ul className="nav nav-small flex-column">
              <li className="nav-item">
                <a className="nav-link" href="/subject">
                  ARTS1090
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/subject">
                  ARTS1840
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/subject">
                  BENV1042
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/subject">
                  MDIA1000
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/overview">
                  Overview
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <hr />

      <div className="d-none d-lg-block w-100">
        <span className="text-small text-muted">Quick links</span>

        <ul className="nav nav-small flex-column mt-2">
          <li className="nav-item">
            <a href="/calendar" className="nav-link">
              Calendar
            </a>
          </li>

          <li className="nav-item">
            <a href="/archive" className="nav-link">
              Archive
            </a>
          </li>

          <li className="nav-item">
            <a href="/changelog" className="nav-link">
              Changelog
            </a>
          </li>
        </ul>

        <hr />
      </div>
      <div>
        <form>
          <div className="input-group input-group-dark input-group-round">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="material-icons">search</i>
              </span>
            </div>
            <input
              type="search"
              className="form-control form-control-dark"
              placeholder="Search"
              aria-label="Search app"
              aria-describedby="search-app"
            />
          </div>
        </form>
        <div className="dropdown mt-2">
          <button
            className="btn btn-primary btn-block dropdown-toggle"
            type="button"
            id="newContentButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Add New
          </button>
          <div className="dropdown-menu" aria-labelledby="newContentButton">
            <a className="dropdown-item" href="#">
              Subject
            </a>
            <a className="dropdown-item" href="#">
              Notes
            </a>
            <a className="dropdown-item" href="#">
              Assignment
            </a>
            <a className="dropdown-item" href="#">
              Exam
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="d-none d-lg-block">
      <div className="dropup">
        <a
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img alt="Image" src={user.avatar} className="avatar" />
        </a>
        <div className="dropdown-menu">
          <a href="/account" className="dropdown-item">
            Account
          </a>
          <a href="#" className="dropdown-item">
            Log Out
          </a>
        </div>
      </div>
    </div>
  </div>
);
