import React from "react";
import NoteItem from "./NoteItem";

export default () => (
  <div className="content-list">
    <div className="row content-list-head">
      <div className="col-auto">
        <h3>Notes</h3>
        <button
          className="btn btn-round"
          data-toggle="modal"
          data-target="#note-add-modal"
        >
          <i className="material-icons">add</i>
        </button>
      </div>
      <form className="col-md-auto">
        <div className="input-group input-group-round">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="material-icons">filter_list</i>
            </span>
          </div>
          <input
            type="search"
            className="form-control filter-list-input"
            placeholder="Filter notes"
            aria-label="Filter notes"
            aria-describedby="filter-notes"
          />
        </div>
      </form>
    </div>

    <div className="content-list-body">
      <NoteItem title="First meeting notes" updated="Just now">
        <img
          alt="Peggy Brown"
          src="/images/theme/avatar-female-2.jpg"
          className="avatar"
          data-toggle="tooltip"
          data-title="Peggy Brown"
        />
      </NoteItem>

      <NoteItem title="Client preference" updated="Yesterday">
        <img
          alt="David Whittaker"
          src="/images/theme/avatar-male-4.jpg"
          className="avatar"
          data-toggle="tooltip"
          data-title="David Whittaker"
        />
      </NoteItem>

      <NoteItem />

      <div className="card card-note">
        <div className="card-header">
          <div className="media align-items-center">
            <div className="media-body">
              <h6 className="mb-0">
                Client preference
              </h6>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span>Yesterday</span>
            <div className="ml-1 dropdown card-options">
              <button
                className="btn-options"
                type="button"
                id="note-dropdown-button-2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#">
                  Edit
                </a>
                <a className="dropdown-item text-danger" href="#">
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <p>
            Hi all, just wanted to add that the client has requested that we
            lean toward a &#39;friendly&#39; aesthetic. I know this seems a
            little vague but it does give us a starting point for the mood
            boards. I recommend we use larger corporates who target
            &#39;youthful&#39; audiences as initial inspiration.{" "}
            <a href="#">@Peggy</a> will take the lead from here.
          </p>
        </div>
      </div>

      <div className="card card-note">
        <div className="card-header">
          <div className="media align-items-center">
            <img
              alt="Ravi Singh"
              src="/images/theme/avatar-male-3.jpg"
              className="avatar"
              data-toggle="tooltip"
              data-title="Ravi Singh"
            />
            <div className="media-body">
              <h6 className="mb-0">
                Matboard links
              </h6>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span>Just now</span>
            <div className="ml-1 dropdown card-options">
              <button
                className="btn-options"
                type="button"
                id="note-dropdown-button-3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#">
                  Edit
                </a>
                <a className="dropdown-item text-danger" href="#">
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <p>
            Hey guys, here&#39;s the link to the Matboards:{" "}
            <a href="#">https://matboard.io/3928462</a>
          </p>
        </div>
      </div>
    </div>
  </div>
);
