import React from "react";
import Breadcrumb from "../layout/Breadcrumb";
import AvatarList from "../components/Users/AvatarList";

export default () => (
  <>
    <Breadcrumb breadcrumb="Archive" />

    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11 col-xl-10">
          <div className="page-header">
            <h1>Archive</h1>
            <p className="lead">
              Subjects you are no longer studying get saved to your archive.
            </p>
          </div>

          <div className="row content-list-head">
            <div className="col-auto">
              <h3>Subjects</h3>
            </div>
            <form className="col-md-auto">
              <div className="input-group input-group-round">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="material-icons">filter_list</i>
                  </span>
                </div>
                <input
                  type="search"
                  className="form-control filter-list-input"
                  placeholder="Filter subjects"
                  aria-label="Filter Tasks"
                  aria-describedby="filter-tasks"
                />
              </div>
            </form>
          </div>

          <div className="content-list-body row">
            <div className="col-12">
              <div className="card card-task">
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div className="card-body">
                  <div className="card-title">
                    <a href="#">
                      <h6>AVEN1920</h6>
                    </a>
                    <span className="text-small">High Distinction</span>
                  </div>
                  <div className="card-meta">
                    <AvatarList />

                    <div className="d-flex align-items-center">
                      <i className="material-icons">playlist_add_check</i>
                      <span>4/4</span>
                    </div>
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="task-dropdown-button-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Mark as done
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#">
                          Archive
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card card-task">
                <div className="progress">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: "75%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div className="card-body">
                  <div className="card-title">
                    <a href="#">
                      <h6>AVIA1321</h6>
                    </a>
                    <span className="text-small">Distinction</span>
                  </div>
                  <div className="card-meta">
                    <AvatarList />

                    <div className="d-flex align-items-center">
                      <i className="material-icons">playlist_add_check</i>
                      <span>2/10</span>
                    </div>
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="task-dropdown-button-2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Mark as done
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#">
                          Archive
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card card-task">
                <div className="progress">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: "60%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div className="card-body">
                  <div className="card-title">
                    <a href="#">
                      <h6>PHYS1149</h6>
                    </a>
                    <span className="text-small">Credit</span>
                  </div>
                  <div className="card-meta">
                    <AvatarList />

                    <div className="d-flex align-items-center">
                      <i className="material-icons">playlist_add_check</i>
                      <span>0/6</span>
                    </div>
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="task-dropdown-button-3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Mark as done
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#">
                          Archive
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card card-task">
                <div className="progress">
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{ width: "60%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div className="card-body">
                  <div className="card-title">
                    <a href="#">
                      <h6>MATH1031</h6>
                    </a>
                    <span className="text-small">Credit</span>
                  </div>
                  <div className="card-meta">
                    <AvatarList />

                    <div className="d-flex align-items-center">
                      <i className="material-icons">playlist_add_check</i>
                      <span>0/6</span>
                    </div>
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="task-dropdown-button-3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Mark as done
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="#">
                          Archive
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
