import React from "react";
import TaskItem from "./TaskItem";

export default () => (
  <div className="content-list">
    <div className="row content-list-head">
      <div className="col-auto">
        <h3>Todos</h3>
        <button
          className="btn btn-round"
          data-toggle="tooltip"
          data-title="New item"
        >
          <i className="material-icons">add</i>
        </button>
      </div>
      <form className="col-md-auto">
        <div className="input-group input-group-round">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="material-icons">filter_list</i>
            </span>
          </div>
          <input
            type="search"
            className="form-control filter-list-input"
            placeholder="Filter checklist"
            aria-label="Filter checklist"
            aria-describedby="filter-checklist"
          />
        </div>
      </form>
    </div>
    <div className="content-list-body">
      <form className="checklist">
        <TaskItem task="Do the first week's readings" checked />
        <TaskItem task="Purchase notes from StudentVIP Notes" checked />
        <TaskItem task="Meet a nice girl" />
        <div className="row">
          <div className="form-group col">
            <span className="checklist-reorder">
              <i className="material-icons">reorder</i>
            </span>
            <div className="custom-control custom-checkbox col">
              <input
                type="checkbox"
                className="custom-control-input"
                id="checklist-item-3"
                checked
              />
              <label className="custom-control-label" for="checklist-item-3" />
              <div>
                <input
                  type="text"
                  placeholder="Checklist item"
                  value="Identify three distinct aesthetic styles for boards"
                />
                <div className="checklist-strikethrough" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <span className="checklist-reorder">
              <i className="material-icons">reorder</i>
            </span>
            <div className="custom-control custom-checkbox col">
              <input
                type="checkbox"
                className="custom-control-input"
                id="checklist-item-4"
              />
              <label className="custom-control-label" for="checklist-item-4" />
              <div>
                <input
                  type="text"
                  placeholder="Checklist item"
                  value="Add aesthetic style descriptions as notes"
                />
                <div className="checklist-strikethrough" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <span className="checklist-reorder">
              <i className="material-icons">reorder</i>
            </span>
            <div className="custom-control custom-checkbox col">
              <input
                type="checkbox"
                className="custom-control-input"
                id="checklist-item-5"
              />
              <label className="custom-control-label" for="checklist-item-5" />
              <div>
                <input
                  type="text"
                  placeholder="Checklist item"
                  value="Assemble boards using inspiration from Dribbble, Land Book, Nicely Done etc."
                />
                <div className="checklist-strikethrough" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <span className="checklist-reorder">
              <i className="material-icons">reorder</i>
            </span>
            <div className="custom-control custom-checkbox col">
              <input
                type="checkbox"
                className="custom-control-input"
                id="checklist-item-6"
              />
              <label className="custom-control-label" for="checklist-item-6" />
              <div>
                <input
                  type="text"
                  placeholder="Checklist item"
                  value="Gather feedback from project team"
                />
                <div className="checklist-strikethrough" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <span className="checklist-reorder">
              <i className="material-icons">reorder</i>
            </span>
            <div className="custom-control custom-checkbox col">
              <input
                type="checkbox"
                className="custom-control-input"
                id="checklist-item-7"
              />
              <label className="custom-control-label" for="checklist-item-7" />
              <div>
                <input
                  type="text"
                  placeholder="Checklist item"
                  value="Invite clients to board before next concept meeting"
                />
                <div className="checklist-strikethrough" />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="drop-to-delete">
        <div className="drag-to-delete-title">
          <i className="material-icons">delete</i>
        </div>
      </div>
    </div>
  </div>
);
