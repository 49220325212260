import female1 from "../images/theme/avatar-female-1.jpg";
import female2 from "../images/theme/avatar-female-2.jpg";
import female3 from "../images/theme/avatar-female-3.jpg";
import female4 from "../images/theme/avatar-female-4.jpg";
import female5 from "../images/theme/avatar-female-5.jpg";
import female6 from "../images/theme/avatar-female-6.jpg";
import male1 from "../images/theme/avatar-male-1.jpg";
import male2 from "../images/theme/avatar-male-2.jpg";
import male3 from "../images/theme/avatar-male-3.jpg";
import male4 from "../images/theme/avatar-male-4.jpg";
import male5 from "../images/theme/avatar-male-5.jpg";
import male6 from "../images/theme/avatar-male-6.jpg";

import avatar from "../images/avatar.jpg";


class User {
  constructor(id, name, avatar) {
    this.id = id;
    this.name = name;
    this.avatar = avatar;
  }
};

export const user = new User(0, "Dwight Watson", avatar);

export default [
  new User(1, "Claire Connors", female1),
  new User(2, "Marcus Simmons", male1),
  new User(3, "Peggy Brown", female2),
  new User(4, "Harry Xai", male2),
  new User(5, "Sally Harper", female3),
  new User(6, "Ravi Singh", male3),
  new User(7, "Kristina Van Der Stroem", female4),
  new User(8, "David Whittaker", male4),
  new User(9, "Kerri-Anne Banks", female5),
  new User(10, "Masimba Sibanda", male5),
  new User(11, "Krishna Bajaj", female6),
  new User(12, "Kenny Tran", male6),
];
