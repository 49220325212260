import React from "react";

export default ({ user }) => (
  <li>
    <a
      href="#"
      data-toggle="tooltip"
      data-placement="top"
      title={user.name}
    >
      <img
        alt={user.name}
        className="avatar"
        src={user.avatar}
      />
    </a>
  </li>
)
