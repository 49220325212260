import React from "react";
import Breadcrumb from "../layout/Breadcrumb";

export default () => (
  <>
    <Breadcrumb breadcrumb="Account" />

    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-3 mb-3">
          <ul className="nav nav-tabs flex-lg-column">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="true"
              >
                Your Profile
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="password-tab"
                data-toggle="tab"
                href="#password"
                role="tab"
                aria-controls="password"
                aria-selected="false"
              >
                Password
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="notifications-tab"
                data-toggle="tab"
                href="#notifications"
                role="tab"
                aria-controls="notifications"
                aria-selected="false"
              >
                Email Notifications
              </a>
            </li>
          </ul>
        </div>
        <div className="col-xl-8 col-lg-9">
          <div className="card">
            <div className="card-body">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  role="tabpanel"
                  id="profile"
                  aria-labelledby="profile-tab"
                >
                  <div className="media mb-4">
                    <img
                      alt="Avatar"
                      src="/images/avatar.jpg"
                      className="avatar avatar-lg"
                    />
                    <div className="media-body ml-3">
                      <div className="custom-file custom-file-naked d-block mb-1">
                        <input
                          type="file"
                          className="custom-file-input d-none"
                          id="avatar-file"
                        />
                        <label
                          className="custom-file-label position-relative"
                          for="avatar-file"
                        >
                          <span className="btn btn-primary">Upload avatar</span>
                        </label>
                      </div>
                      <small>
                        For best results, use an image at least 256px by 256px
                        in either .jpg or .png format
                      </small>
                    </div>
                  </div>

                  <form>
                    <div className="form-group row align-items-center">
                      <label className="col-3">First Name</label>
                      <div className="col">
                        <input
                          type="text"
                          placeholder="First name"
                          value="Dwight"
                          name="profile-first-name"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row align-items-center">
                      <label className="col-3">Last Name</label>
                      <div className="col">
                        <input
                          type="text"
                          placeholder="First name"
                          value="Watson"
                          name="profile-last-name"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row align-items-center">
                      <label className="col-3">Email</label>
                      <div className="col">
                        <input
                          type="email"
                          placeholder="Enter your email address"
                          name="profile-email"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row align-items-center">
                      <label className="col-3">Location</label>
                      <div className="col">
                        <input
                          type="text"
                          placeholder="Enter your location"
                          name="profile-location"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3">Bio</label>
                      <div className="col">
                        <textarea
                          type="text"
                          placeholder="Tell us a little about yourself"
                          name="profile-bio"
                          className="form-control"
                          rows="4"
                        />
                        <small>
                          This will be displayed on your public profile
                        </small>
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="tab-pane fade"
                  role="tabpanel"
                  id="password"
                  aria-labelledby="password-tab"
                >
                  <form>
                    <div className="form-group row align-items-center">
                      <label className="col-3">Current Password</label>
                      <div className="col">
                        <input
                          type="password"
                          placeholder="Enter your current password"
                          name="password-current"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row align-items-center">
                      <label className="col-3">New Password</label>
                      <div className="col">
                        <input
                          type="password"
                          placeholder="Enter a new password"
                          name="password-new"
                          className="form-control"
                        />
                        <small>
                          Password must be at least 8 characters long
                        </small>
                      </div>
                    </div>
                    <div className="form-group row align-items-center">
                      <label className="col-3">Confirm Password</label>
                      <div className="col">
                        <input
                          type="password"
                          placeholder="Confirm your new password"
                          name="password-new-confirm"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="tab-pane fade"
                  role="tabpanel"
                  id="notifications"
                  aria-labelledby="notifications-tab"
                >
                  <form>
                    <h6>Activity Notifications</h6>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox custom-checkbox-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notify-1"
                          checked
                        />
                        <label className="custom-control-label" for="notify-1">
                          Someone assigns me to a task
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox custom-checkbox-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notify-2"
                          checked
                        />
                        <label className="custom-control-label" for="notify-2">
                          Someone mentions me in a conversation
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox custom-checkbox-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notify-3"
                          checked
                        />
                        <label className="custom-control-label" for="notify-3">
                          Someone adds me to a project
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-md-4">
                      <div className="custom-control custom-checkbox custom-checkbox-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notify-4"
                        />
                        <label className="custom-control-label" for="notify-4">
                          Activity on a project I am a member of
                        </label>
                      </div>
                    </div>
                    <h6>Service Notifications</h6>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox custom-checkbox-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notify-5"
                        />
                        <label className="custom-control-label" for="notify-5">
                          Monthly newsletter
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox custom-checkbox-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notify-6"
                          checked
                        />
                        <label className="custom-control-label" for="notify-6">
                          Major feature enhancements
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox custom-checkbox-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="notify-7"
                        />
                        <label className="custom-control-label" for="notify-7">
                          Minor updates and bug fixes
                        </label>
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Save preferences
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
