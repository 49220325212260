import React from "react";
import Breadcrumb from "../layout/Breadcrumb";
import AvatarList from "../components/Users/AvatarList";
import TaskList from "../components/Tasks/TaskList";

export default () => (
  <>
    <Breadcrumb breadcrumb="Today" />

    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11 col-xl-10">
          <div className="page-header">
            <h1>Today</h1>
            <p className="lead">
              What you have on today, and what's on the radar.
            </p>
          </div>
          <hr />
          <ul className="nav nav-tabs nav-fill">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#projects"
                role="tab"
                aria-controls="projects"
                aria-selected="true"
              >
                Today
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#upcoming"
                role="tab"
                aria-controls="upcoming"
                aria-selected="false"
              >
                Upcoming
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="projects"
              role="tabpanel"
              aria-labelledby="tasks-tab"
              data-filter-list="content-list-body"
            >
              <TaskList />

              <div className="content-list">
                <div className="row content-list-head">
                  <div className="col-auto">
                    <h3>Projects</h3>
                    <button
                      className="btn btn-round"
                      data-toggle="modal"
                      data-target="#project-add-modal"
                    >
                      <i className="material-icons">add</i>
                    </button>
                  </div>
                  <form className="col-md-auto">
                    <div className="input-group input-group-round">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="material-icons">filter_list</i>
                        </span>
                      </div>
                      <input
                        type="search"
                        className="form-control filter-list-input"
                        placeholder="Filter projects"
                        aria-label="Filter Projects"
                        aria-describedby="filter-projects"
                      />
                    </div>
                  </form>
                </div>
                <div className="content-list-body row">
                  <div className="col-lg-6">
                    <div className="card card-project">
                      <div className="progress">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <div className="card-body">
                        <div className="dropdown card-options">
                          <button
                            className="btn-options"
                            type="button"
                            id="project-dropdown-button-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              Share
                            </a>
                          </div>
                        </div>
                        <div className="card-title">
                          <a href="#">
                            <h5>New Website</h5>
                          </a>
                        </div>
                        <ul className="avatars">
                          <li>
                            <a href="#" data-toggle="tooltip" title="Kenny">
                              <img
                                alt="Kenny Tran"
                                className="avatar"
                                src="/images/theme/avatar-male-6.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Peggy">
                              <img
                                alt="Peggy Brown"
                                className="avatar"
                                src="/images/theme/avatar-female-2.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Ravi">
                              <img
                                alt="Ravi Singh"
                                className="avatar"
                                src="/images/theme/avatar-male-3.jpg"
                              />
                            </a>
                          </li>
                        </ul>
                        <div className="card-meta d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="material-icons mr-1">
                              playlist_add_check
                            </i>
                            <span className="text-small">6/10</span>
                          </div>
                          <span className="text-small">
                            Due 4 days
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-project">
                      <div className="progress">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "20%" }}
                          aria-valuenow="20"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <div className="card-body">
                        <div className="dropdown card-options">
                          <button
                            className="btn-options"
                            type="button"
                            id="project-dropdown-button-2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              Share
                            </a>
                          </div>
                        </div>
                        <div className="card-title">
                          <a href="#">
                            <h5>
                              Company Vision Statement
                            </h5>
                          </a>
                        </div>
                        <ul className="avatars">
                          <li>
                            <a href="#" data-toggle="tooltip" title="Harry">
                              <img
                                alt="Harry Xai"
                                className="avatar"
                                src="/images/theme/avatar-male-2.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Sally">
                              <img
                                alt="Sally Harper"
                                className="avatar"
                                src="/images/theme/avatar-female-3.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Kristina">
                              <img
                                alt="Kristina Van Der Stroem"
                                className="avatar"
                                src="/images/theme/avatar-female-4.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-toggle="tooltip"
                              title="Kerri-Anne"
                            >
                              <img
                                alt="Kerri-Anne Banks"
                                className="avatar"
                                src="/images/theme/avatar-female-5.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Claire">
                              <img
                                alt="Claire Connors"
                                className="avatar"
                                src="/images/theme/avatar-female-1.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Marcus">
                              <img
                                alt="Marcus Simmons"
                                className="avatar"
                                src="/images/theme/avatar-male-1.jpg"
                              />
                            </a>
                          </li>
                        </ul>
                        <div className="card-meta d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="material-icons mr-1">
                              playlist_add_check
                            </i>
                            <span className="text-small">1/8</span>
                          </div>
                          <span className="text-small">
                            Due 7 days
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-project">
                      <div className="progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "25%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <div className="card-body">
                        <div className="dropdown card-options">
                          <button
                            className="btn-options"
                            type="button"
                            id="project-dropdown-button-3"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              Share
                            </a>
                          </div>
                        </div>
                        <div className="card-title">
                          <a href="#">
                            <h5>Update Privacy Policy</h5>
                          </a>
                        </div>
                        <ul className="avatars">
                          <li>
                            <a href="#" data-toggle="tooltip" title="Claire">
                              <img
                                alt="Claire Connors"
                                className="avatar"
                                src="/images/theme/avatar-female-1.jpg"
                              />
                            </a>
                          </li>
                        </ul>
                        <div className="card-meta d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="material-icons mr-1">
                              playlist_add_check
                            </i>
                            <span className="text-small">2/8</span>
                          </div>
                          <span className="text-small">
                            Due 8 days
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-project">
                      <div className="card-body">
                        <div className="dropdown card-options">
                          <button
                            className="btn-options"
                            type="button"
                            id="project-dropdown-button-4"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              Share
                            </a>
                          </div>
                        </div>
                        <div className="card-title">
                          <a href="#">
                            <h5>
                              Update Marketing Collateral
                            </h5>
                          </a>
                        </div>
                        <ul className="avatars">
                          <li>
                            <a href="#" data-toggle="tooltip" title="David">
                              <img
                                alt="David Whittaker"
                                className="avatar"
                                src="/images/theme/avatar-male-4.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Masimba">
                              <img
                                alt="Masimba Sibanda"
                                className="avatar"
                                src="/images/theme/avatar-male-5.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Krishna">
                              <img
                                alt="Krishna Bajaj"
                                className="avatar"
                                src="/images/theme/avatar-female-6.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Harry">
                              <img
                                alt="Harry Xai"
                                className="avatar"
                                src="/images/theme/avatar-male-2.jpg"
                              />
                            </a>
                          </li>
                        </ul>
                        <div className="card-meta d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="material-icons mr-1">
                              playlist_add_check
                            </i>
                            <span className="text-small">0/5</span>
                          </div>
                          <span className="text-small">
                            Due 12 days
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-project">
                      <div className="progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "8%" }}
                          aria-valuenow="8"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <div className="card-body">
                        <div className="dropdown card-options">
                          <button
                            className="btn-options"
                            type="button"
                            id="project-dropdown-button-5"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              Share
                            </a>
                          </div>
                        </div>
                        <div className="card-title">
                          <a href="#">
                            <h5>
                              Brand Concept &amp; Design
                            </h5>
                          </a>
                        </div>
                        <ul className="avatars">
                          <li>
                            <a href="#" data-toggle="tooltip" title="Ravi">
                              <img
                                alt="Ravi Singh"
                                className="avatar"
                                src="/images/theme/avatar-male-3.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Masimba">
                              <img
                                alt="Masimba Sibanda"
                                className="avatar"
                                src="/images/theme/avatar-male-5.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Peggy">
                              <img
                                alt="Peggy Brown"
                                className="avatar"
                                src="/images/theme/avatar-female-2.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Marcus">
                              <img
                                alt="Marcus Simmons"
                                className="avatar"
                                src="/images/theme/avatar-male-1.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-toggle="tooltip"
                              title="Kerri-Anne"
                            >
                              <img
                                alt="Kerri-Anne Banks"
                                className="avatar"
                                src="/images/theme/avatar-female-5.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Claire">
                              <img
                                alt="Claire Connors"
                                className="avatar"
                                src="/images/theme/avatar-female-1.jpg"
                              />
                            </a>
                          </li>
                        </ul>
                        <div className="card-meta d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="material-icons mr-1">
                              playlist_add_check
                            </i>
                            <span className="text-small">1/12</span>
                          </div>
                          <span className="text-small">
                            Due 20 days
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-project">
                      <div className="card-body">
                        <div className="dropdown card-options">
                          <button
                            className="btn-options"
                            type="button"
                            id="project-dropdown-button-6"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              Share
                            </a>
                          </div>
                        </div>
                        <div className="card-title">
                          <a href="#">
                            <h5>Company Getaway</h5>
                          </a>
                        </div>
                        <ul className="avatars">
                          <li>
                            <a href="#" data-toggle="tooltip" title="Claire">
                              <img
                                alt="Claire Connors"
                                className="avatar"
                                src="/images/theme/avatar-female-1.jpg"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="#" data-toggle="tooltip" title="Kristina">
                              <img
                                alt="Kristina Van Der Stroem"
                                className="avatar"
                                src="/images/theme/avatar-female-4.jpg"
                              />
                            </a>
                          </li>
                        </ul>
                        <div className="card-meta d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="material-icons mr-1">
                              playlist_add_check
                            </i>
                            <span className="text-small">-/-</span>
                          </div>
                          <span className="text-small">
                            Unscheduled
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-project">
                      <div className="card-body">
                        <div className="dropdown card-options">
                          <button
                            className="btn-options"
                            type="button"
                            id="project-dropdown-button-7"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#">
                              Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              Share
                            </a>
                          </div>
                        </div>
                        <div className="card-title">
                          <a href="#">
                            <h5>Annual Team Meeting</h5>
                          </a>
                        </div>
                        <ul className="avatars">
                          <li>
                            <a href="#" data-toggle="tooltip" title="Ravi">
                              <img
                                alt="Ravi Singh"
                                className="avatar"
                                src="/images/theme/avatar-male-3.jpg"
                              />
                            </a>
                          </li>
                        </ul>
                        <div className="card-meta d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="material-icons mr-1">
                              playlist_add_check
                            </i>
                            <span className="text-small">-/-</span>
                          </div>
                          <span className="text-small">
                            Unscheduled
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="upcoming"
              role="tabpanel"
              aria-labelledby="upcoming-tab"
              data-filter-list="content-list-body"
            >
              <div className="card card-project">
                <div className="card-body">
                  <div className="dropdown card-options">
                    <button
                      className="btn-options"
                      type="button"
                      id="project-dropdown-button-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#">
                        Edit
                      </a>
                      <a className="dropdown-item" href="#">
                        Share
                      </a>
                    </div>
                  </div>
                  <div className="card-title">
                    <a href="#">
                      <h5 className="mb-1">ARTS1091 Tutorial</h5>
                    </a>
                    <h6>Orange Building, M13</h6>
                  </div>

                  <AvatarList />

                  <div className="card-meta d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <i className="material-icons mr-1">playlist_add_check</i>
                      <span className="text-small">I'm going</span>
                    </div>
                    <span className="text-small">
                      in 31 minutes
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form
            className="modal fade"
            id="user-invite-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="user-invite-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Invite Users</h5>
                  <button
                    type="button"
                    className="close btn btn-round"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Send an invite link via email to add members to this team
                  </p>
                  <div>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="material-icons">email</i>
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Recipient email address"
                        aria-label="Recipient email address"
                        aria-describedby="recipient-email-address"
                      />
                    </div>
                    <div className="text-right text-small mt-2">
                      <a href="#" role="button">
                        Add another recipient
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    role="button"
                    className="btn btn-primary"
                    type="submit"
                  >
                    Invite users
                  </button>
                </div>
              </div>
            </div>
          </form>
          <form
            className="modal fade"
            id="team-manage-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="team-manage-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Manage Team</h5>
                  <button
                    type="button"
                    className="close btn btn-round"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                </div>
                <ul className="nav nav-tabs nav-fill">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="team-manage-details-tab"
                      data-toggle="tab"
                      href="#team-manage-details"
                      role="tab"
                      aria-controls="team-manage-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="team-manage-members-tab"
                      data-toggle="tab"
                      href="#team-manage-members"
                      role="tab"
                      aria-controls="team-manage-members"
                      aria-selected="false"
                    >
                      Members
                    </a>
                  </li>
                </ul>
                <div className="modal-body">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="team-manage-details"
                      role="tabpanel"
                      aria-labelledby="team-manage-details-tab"
                    >
                      <h6>Team Details</h6>
                      <div className="form-group row align-items-center">
                        <label className="col-3">Name</label>
                        <input
                          className="form-control col"
                          type="text"
                          placeholder="Team name"
                          name="team-name"
                          value="Medium Rare"
                        />
                      </div>
                      <div className="form-group row">
                        <label className="col-3">Description</label>
                        <textarea
                          className="form-control col"
                          rows="3"
                          placeholder="Team description"
                          name="team-description"
                        >
                          A small web studio crafting lovely template products.
                        </textarea>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="team-manage-members"
                      role="tabpanel"
                      aria-labelledby="team-manage-members-tab"
                    >
                      <div
                        className="users-manage"
                        data-filter-list="form-group-users"
                      >
                        <div className="mb-3">
                          <ul className="avatars text-center">
                            <li>
                              <img
                                alt="Claire Connors"
                                src="/images/theme/avatar-female-1.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Claire Connors"
                              />
                            </li>
                            <li>
                              <img
                                alt="Marcus Simmons"
                                src="/images/theme/avatar-male-1.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Marcus Simmons"
                              />
                            </li>
                            <li>
                              <img
                                alt="Peggy Brown"
                                src="/images/theme/avatar-female-2.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Peggy Brown"
                              />
                            </li>
                            <li>
                              <img
                                alt="Harry Xai"
                                src="/images/theme/avatar-male-2.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Harry Xai"
                              />
                            </li>
                          </ul>
                        </div>
                        <div className="input-group input-group-round">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">filter_list</i>
                            </span>
                          </div>
                          <input
                            type="search"
                            className="form-control filter-list-input"
                            placeholder="Filter members"
                            aria-label="Filter Members"
                            aria-describedby="filter-members"
                          />
                        </div>
                        <div className="form-group-users">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-1"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-1"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Claire Connors"
                                  src="/images/theme/avatar-female-1.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Claire Connors
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-2"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-2"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Marcus Simmons"
                                  src="/images/theme/avatar-male-1.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Marcus Simmons
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-3"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-3"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Peggy Brown"
                                  src="/images/theme/avatar-female-2.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Peggy Brown
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-4"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-4"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Harry Xai"
                                  src="/images/theme/avatar-male-2.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Harry Xai
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-5"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-5"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Sally Harper"
                                  src="/images/theme/avatar-female-3.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Sally Harper
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-6"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-6"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Ravi Singh"
                                  src="/images/theme/avatar-male-3.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Ravi Singh
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-7"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-7"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Kristina Van Der Stroem"
                                  src="/images/theme/avatar-female-4.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Kristina Van Der Stroem
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-8"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-8"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="David Whittaker"
                                  src="/images/theme/avatar-male-4.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  David Whittaker
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-9"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-9"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Kerri-Anne Banks"
                                  src="/images/theme/avatar-female-5.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Kerri-Anne Banks
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-10"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-10"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Masimba Sibanda"
                                  src="/images/theme/avatar-male-5.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Masimba Sibanda
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-11"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-11"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Krishna Bajaj"
                                  src="/images/theme/avatar-female-6.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Krishna Bajaj
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="user-manage-12"
                            />
                            <label
                              className="custom-control-label"
                              for="user-manage-12"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Kenny Tran"
                                  src="/images/theme/avatar-male-6.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Kenny Tran
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    role="button"
                    className="btn btn-primary"
                    type="submit"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </form>
          <form
            className="modal fade"
            id="project-add-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="project-add-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">New Project</h5>
                  <button
                    type="button"
                    className="close btn btn-round"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                </div>
                <ul className="nav nav-tabs nav-fill">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="project-add-details-tab"
                      data-toggle="tab"
                      href="#project-add-details"
                      role="tab"
                      aria-controls="project-add-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="project-add-members-tab"
                      data-toggle="tab"
                      href="#project-add-members"
                      role="tab"
                      aria-controls="project-add-members"
                      aria-selected="false"
                    >
                      Members
                    </a>
                  </li>
                </ul>
                <div className="modal-body">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="project-add-details"
                      role="tabpanel"
                      aria-labelledby="project-add-details-tab"
                    >
                      <h6>General Details</h6>
                      <div className="form-group row align-items-center">
                        <label className="col-3">Name</label>
                        <input
                          className="form-control col"
                          type="text"
                          placeholder="Project name"
                          name="project-name"
                        />
                      </div>
                      <div className="form-group row">
                        <label className="col-3">Description</label>
                        <textarea
                          className="form-control col"
                          rows="3"
                          placeholder="Project description"
                          name="project-description"
                        />
                      </div>
                      <hr />
                      <h6>Timeline</h6>
                      <div className="form-group row align-items-center">
                        <label className="col-3">Start Date</label>
                        <input
                          className="form-control col"
                          type="date"
                          placeholder="Project start"
                          name="project-start"
                        />
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col-3">Due Date</label>
                        <input
                          className="form-control col"
                          type="date"
                          placeholder="Project due"
                          name="project-due"
                        />
                      </div>
                      <div
                        className="alert alert-warning text-small"
                        role="alert"
                      >
                        <span>You can change due dates at any time.</span>
                      </div>
                      <hr />
                      <h6>Visibility</h6>
                      <div className="row">
                        <div className="col">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="visibility-everyone"
                              name="visibility"
                              className="custom-control-input"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="visibility-everyone"
                            >
                              Everyone
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="visibility-members"
                              name="visibility"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              for="visibility-members"
                            >
                              Members
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="visibility-me"
                              name="visibility"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              for="visibility-me"
                            >
                              Just me
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="project-add-members"
                      role="tabpanel"
                      aria-labelledby="project-add-members-tab"
                    >
                      <div
                        className="users-manage"
                        data-filter-list="form-group-users"
                      >
                        <div className="mb-3">
                          <ul className="avatars text-center">
                            <li>
                              <img
                                alt="Claire Connors"
                                src="/images/theme/avatar-female-1.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Claire Connors"
                              />
                            </li>
                            <li>
                              <img
                                alt="Marcus Simmons"
                                src="/images/theme/avatar-male-1.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Marcus Simmons"
                              />
                            </li>
                            <li>
                              <img
                                alt="Peggy Brown"
                                src="/images/theme/avatar-female-2.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Peggy Brown"
                              />
                            </li>
                            <li>
                              <img
                                alt="Harry Xai"
                                src="/images/theme/avatar-male-2.jpg"
                                className="avatar"
                                data-toggle="tooltip"
                                data-title="Harry Xai"
                              />
                            </li>
                          </ul>
                        </div>
                        <div className="input-group input-group-round">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">filter_list</i>
                            </span>
                          </div>
                          <input
                            type="search"
                            className="form-control filter-list-input"
                            placeholder="Filter members"
                            aria-label="Filter Members"
                            aria-describedby="filter-members"
                          />
                        </div>
                        <div className="form-group-users">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-1"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-1"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Claire Connors"
                                  src="/images/theme/avatar-female-1.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Claire Connors
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-2"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-2"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Marcus Simmons"
                                  src="/images/theme/avatar-male-1.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Marcus Simmons
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-3"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-3"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Peggy Brown"
                                  src="/images/theme/avatar-female-2.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Peggy Brown
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-4"
                              checked
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-4"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Harry Xai"
                                  src="/images/theme/avatar-male-2.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Harry Xai
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-5"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-5"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Sally Harper"
                                  src="/images/theme/avatar-female-3.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Sally Harper
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-6"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-6"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Ravi Singh"
                                  src="/images/theme/avatar-male-3.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Ravi Singh
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-7"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-7"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Kristina Van Der Stroem"
                                  src="/images/theme/avatar-female-4.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Kristina Van Der Stroem
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-8"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-8"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="David Whittaker"
                                  src="/images/theme/avatar-male-4.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  David Whittaker
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-9"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-9"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Kerri-Anne Banks"
                                  src="/images/theme/avatar-female-5.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Kerri-Anne Banks
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-10"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-10"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Masimba Sibanda"
                                  src="/images/theme/avatar-male-5.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Masimba Sibanda
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-11"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-11"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Krishna Bajaj"
                                  src="/images/theme/avatar-female-6.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Krishna Bajaj
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="project-user-12"
                            />
                            <label
                              className="custom-control-label"
                              for="project-user-12"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  alt="Kenny Tran"
                                  src="/images/theme/avatar-male-6.jpg"
                                  className="avatar mr-2"
                                />
                                <span className="h6 mb-0">
                                  Kenny Tran
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    role="button"
                    className="btn btn-primary"
                    type="submit"
                  >
                    Create Project
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
);
