import React from "react";
import Emoji from "../components/Support/Emoji";
import Breadcrumb from "../layout/Breadcrumb";

export default () => (
  <>
    <Breadcrumb breadcrumb="Changelog" />

    <div className="content-container">
      <div className="chat-module" data-filter-list="chat-module-body">
        <div className="chat-module-top">
          <div className="x-chat-module-body">
            <div className="media chat-item">
              <img
                alt="Claire"
                src="/images/theme/avatar-female-1.jpg"
                className="avatar"
              />
              <div className="media-body">
                <div className="chat-item-title">
                  <span className="chat-item-author">
                    Claire
                  </span>
                  <span>4 days ago</span>
                </div>
                <div className="chat-item-body">
                  <h4>Upload course documents</h4>
                  <p>
                    Hey guys, today we're launching a new feature that will
                    allow you to upload course documents to your subjects.
                    Whether it be a course outline or assessment details you can
                    store it right in Studious and access it wherever you go.
                  </p>
                  <p>
                    Course documents can also be marked as public so you can
                    share them with your classmates at the click of a button.
                    Once a course document has been made public you are unable
                    to delete it in case other students are relying on it.
                  </p>
                  <p>
                    As always you can have discussions on your uploads and ask
                    questions and answers. <Emoji name="Nerd">🤓</Emoji>
                  </p>
                </div>
              </div>
            </div>

            <div className="media chat-item">
              <img
                alt="Peggy"
                src="/images/theme/avatar-female-2.jpg"
                className="avatar"
              />
              <div className="media-body">
                <div className="chat-item-title">
                  <span className="chat-item-author">
                    Peggy
                  </span>
                  <span>1 week ago</span>
                </div>
                <div className="chat-item-body">
                  <h4>Flashcards</h4>
                  <p>
                    Today we're introducing flashcards for your subjects. If you
                    find these a useful way to study you can now pop them right
                    into Studious and enter study-mode to test yourself. We'll
                    record your score at the end and you can check if you're
                    improving.
                  </p>
                  <p>
                    If you decide to share your flashcards or want to try one of
                    your classmate's public flashcards your score will be made
                    public and you can try and compete and beat the best.
                  </p>
                  <p>
                    <img
                      src="https://media.giphy.com/media/aTeHNLRLrwwwM/giphy.gif"
                      alt="alt text"
                      title="Thinking"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
