class Subject {
  constructor(id, code, name) {
    this.id = id;
    this.code = code;
    this.name = name;
  }
}

export default [
  new Subject(1, 'ARTS1090', 'Media, Culture and Everyday Life'),
  new Subject(2, 'ARTS1840', 'Australian Politics'),
  new Subject(3, 'BENV1042', 'World Wide Web in Presentation and Communication'),
  new Subject(4, 'MDIA1000', 'Time, Space and Experience'),
]
