import React from "react";
import Breadcrumb from "../layout/Breadcrumb";
import AvatarList from "../components/Users/AvatarList";

export default () => (
  <>
    <Breadcrumb breadcrumb="Assignments">
      <div className="dropdown">
        <button
          className="btn btn-round"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="material-icons">settings</i>
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <a
            className="dropdown-item"
            href="#"
            data-toggle="modal"
            data-target="#team-manage-modal"
          >
            Edit Team
          </a>
          <a className="dropdown-item" href="#">
            Share
          </a>
          <div className="dropdown-divider" />
          <a className="dropdown-item text-danger" href="#">
            Leave
          </a>
        </div>
      </div>
    </Breadcrumb>

    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11 col-xl-10">
          <div className="page-header">
            <h1>ARTS1090</h1>
            <div className="d-flex align-items-center">
              <AvatarList />

              <button
                className="btn btn-round"
                data-toggle="modal"
                data-target="#user-invite-modal"
              >
                <i className="material-icons">add</i>
              </button>
            </div>
          </div>

          <hr />

          <div className="content-list">
            <div className="row content-list-head">
              <div className="col-auto">
                <h3>Assignments</h3>
                <button
                  className="btn btn-round"
                  data-toggle="modal"
                  data-target="#project-add-modal"
                >
                  <i className="material-icons">add</i>
                </button>
              </div>
              <form className="col-md-auto">
                <div className="input-group input-group-round">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="material-icons">filter_list</i>
                    </span>
                  </div>
                  <input
                    type="search"
                    className="form-control filter-list-input"
                    placeholder="Filter projects"
                    aria-label="Filter Projects"
                    aria-describedby="filter-projects"
                  />
                </div>
              </form>
            </div>

            <div className="content-list-body row">
              <div className="col-lg-6">
                <div className="card card-project">
                  <div className="progress">
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ width: "60%" }}
                      aria-valuenow="60"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>

                  <div className="card-body">
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="project-dropdown-button-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          Share
                        </a>
                      </div>
                    </div>
                    <div className="card-title">
                      <a href="#">
                        <h5>New Website</h5>
                      </a>
                    </div>
                    <ul className="avatars">
                      <li>
                        <a href="#" data-toggle="tooltip" title="Kenny">
                          <img
                            alt="Kenny Tran"
                            className="avatar"
                            src="/images/theme/avatar-male-6.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Peggy">
                          <img
                            alt="Peggy Brown"
                            className="avatar"
                            src="/images/theme/avatar-female-2.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Ravi">
                          <img
                            alt="Ravi Singh"
                            className="avatar"
                            src="/images/theme/avatar-male-3.jpg"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="card-meta d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <i className="material-icons mr-1">
                          playlist_add_check
                        </i>
                        <span className="text-small">6/10</span>
                      </div>
                      <span className="text-small">
                        Due 4 days
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-project">
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "20%" }}
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>

                  <div className="card-body">
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="project-dropdown-button-2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          Share
                        </a>
                      </div>
                    </div>
                    <div className="card-title">
                      <a href="#">
                        <h5>Company Vision Statement</h5>
                      </a>
                    </div>
                    <ul className="avatars">
                      <li>
                        <a href="#" data-toggle="tooltip" title="Harry">
                          <img
                            alt="Harry Xai"
                            className="avatar"
                            src="/images/theme/avatar-male-2.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Sally">
                          <img
                            alt="Sally Harper"
                            className="avatar"
                            src="/images/theme/avatar-female-3.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Kristina">
                          <img
                            alt="Kristina Van Der Stroem"
                            className="avatar"
                            src="/images/theme/avatar-female-4.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Kerri-Anne">
                          <img
                            alt="Kerri-Anne Banks"
                            className="avatar"
                            src="/images/theme/avatar-female-5.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Claire">
                          <img
                            alt="Claire Connors"
                            className="avatar"
                            src="/images/theme/avatar-female-1.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Marcus">
                          <img
                            alt="Marcus Simmons"
                            className="avatar"
                            src="/images/theme/avatar-male-1.jpg"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="card-meta d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <i className="material-icons mr-1">
                          playlist_add_check
                        </i>
                        <span className="text-small">1/8</span>
                      </div>
                      <span className="text-small">
                        Due 7 days
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-project">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>

                  <div className="card-body">
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="project-dropdown-button-3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          Share
                        </a>
                      </div>
                    </div>
                    <div className="card-title">
                      <a href="#">
                        <h5>Update Privacy Policy</h5>
                      </a>
                    </div>
                    <ul className="avatars">
                      <li>
                        <a href="#" data-toggle="tooltip" title="Claire">
                          <img
                            alt="Claire Connors"
                            className="avatar"
                            src="/images/theme/avatar-female-1.jpg"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="card-meta d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <i className="material-icons mr-1">
                          playlist_add_check
                        </i>
                        <span className="text-small">2/8</span>
                      </div>
                      <span className="text-small">
                        Due 8 days
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-project">
                  <div className="card-body">
                    <div className="dropdown card-options">
                      <button
                        className="btn-options"
                        type="button"
                        id="project-dropdown-button-4"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          Share
                        </a>
                      </div>
                    </div>
                    <div className="card-title">
                      <a href="#">
                        <h5>
                          Update Marketing Collateral
                        </h5>
                      </a>
                    </div>
                    <ul className="avatars">
                      <li>
                        <a href="#" data-toggle="tooltip" title="David">
                          <img
                            alt="David Whittaker"
                            className="avatar"
                            src="/images/theme/avatar-male-4.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Masimba">
                          <img
                            alt="Masimba Sibanda"
                            className="avatar"
                            src="/images/theme/avatar-male-5.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Krishna">
                          <img
                            alt="Krishna Bajaj"
                            className="avatar"
                            src="/images/theme/avatar-female-6.jpg"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="#" data-toggle="tooltip" title="Harry">
                          <img
                            alt="Harry Xai"
                            className="avatar"
                            src="/images/theme/avatar-male-2.jpg"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="card-meta d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <i className="material-icons mr-1">
                          playlist_add_check
                        </i>
                        <span className="text-small">0/5</span>
                      </div>
                      <span className="text-small">
                        Due 12 days
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
