import React from "react";
import AvatarItem from "./AvatarItem";
import marcus from "../../images/theme/avatar-male-1.jpg";
import claire from "../../images/theme/avatar-female-1.jpg";
import david from "../../images/theme/avatar-male-4.jpg";

export default () => (
  <ul className="avatars">
    <AvatarItem name="Marcus Simmons" avatar={marcus} />
    <AvatarItem name="Claire Connors" avatar={claire} />
    <AvatarItem name="David Whittaker" avatar={david} />
  </ul>
);
