import React from "react";
import { Tooltip } from "reactstrap";
import { Link } from "react-router-dom";

export default ({ name, avatar }) => (
  <li>
    <Link to="/user" title={name} data-toggle="tooltip">
      <img alt={name} className="avatar" src={avatar} />
    </Link>
  </li>
);
