import React from "react";
export default () => (
  <form
    className="modal fade"
    tabIndex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Invite Users</h5>
          <button
            type="button"
            className="close btn btn-round"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="material-icons">close</i>
          </button>
        </div>

        <div className="modal-body">
          <p>Send an invite link via email to add members to this team</p>
          <div>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">email</i>
                </span>
              </div>
              <input
                type="email"
                className="form-control"
                placeholder="Recipient email address"
                aria-label="Recipient email address"
                aria-describedby="recipient-email-address"
              />
            </div>
            <div className="text-right text-small mt-2">
              <a href="#" role="button">
                Add another recipient
              </a>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn btn-primary" type="submit">
            Invite users
          </button>
        </div>
      </div>
    </div>
  </form>
)
