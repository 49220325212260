import React, { useState } from "react";
import UserItem from "./UserItem";
import InviteUserModal from "./InviteUserModal.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import users from '../../api/users';


const UserList = () => {
  const [showModal, setShowModal] = useState(true);

  return (
    <>
      <div className="d-flex align-items-center">
        <ul className="avatars">
          {users.map(user => <UserItem key={user.id} user={user} />)}
        </ul>
        <button
          className="btn btn-round"
          onClick={() => setShowModal(true)}
        >
          <i className="material-icons">add</i>
        </button>
      </div>

      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalBody>
          <InviteUserModal />
        </ModalBody>
      </Modal>

    </>
  );
}

export default UserList;
