import React from "react";
import { Link } from "react-router-dom";

export default ({ breadcrumb, children }) => (
  <div className="navbar bg-white breadcrumb-bar sticky-top">
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>

        <li className="breadcrumb-item active" aria-current="page">
          {breadcrumb}
        </li>
      </ol>
    </nav>

    {children}
  </div>
);
