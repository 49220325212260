import React from "react";

export default ({ task, checked = false }) => (
  <div className="row">
    <div className="form-group col">
      <span className="checklist-reorder">
        <i className="material-icons">reorder</i>
      </span>
      <div className="custom-control custom-checkbox col">
        <input
          type="checkbox"
          className="custom-control-input"
          id="checklist-item-3"
          checked={checked}
        />
        <label className="custom-control-label" for="checklist-item-3" />
        <div>
          <input
            type="text"
            placeholder="Checklist item"
            value={task}
          />
          <div className="checklist-strikethrough" />
        </div>
      </div>
    </div>
  </div>
);
