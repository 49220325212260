import React from "react";
import planner from "../images/planner.svg";

import navSideTeam from "../images/theme/overview/nav-side-team.png";
import navSideProject from "../images/theme/overview/nav-side-project.png";
import navSideTask from "../images/theme/overview/nav-side-task.png";
import navSideKanbanBoard from "../images/theme/overview/nav-side-kanban-board.png";
import navSideChat from "../images/theme/overview/nav-side-chat.png";
import navSideUser from "../images/theme/overview/nav-side-user.png";
import activity from "../images/theme/components/activity.png";
import avatar from "../images/theme/components/avatar.png";
import avatarList from "../images/theme/components/avatar-list.png";
import cardKanban from "../images/theme/components/card-kanban.png";
import cardNote from "../images/theme/components/card-note.png";
import cardProject from "../images/theme/components/card-project.png";
import cardTeam from "../images/theme/components/card-team.png";
import cardTask from "../images/theme/components/card-task.png";
import cardList from "../images/theme/components/card-list.png";
import chatItem from "../images/theme/components/chat-item.png";
import chatModule from "../images/theme/components/chat-module.png";
import checklist from "../images/theme/components/checklist.png";
import layoutNavSide from "../images/theme/layouts/layout-nav-side.svg";
import layoutNavTop from "../images/theme/layouts/layout-nav-top.svg";
import layoutNavTopSidebar from "../images/theme/layouts/layout-nav-top-sidebar.svg";

export default () => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-11 col-xl-9">
        <section className="py-4 py-lg-5">
          <div className="mb-3 d-flex">
            <img
              alt="Planner"
              src={planner}
              className="avatar avatar-lg mr-1"
            />
            <div>
              <span className="badge badge-success">1.0</span>
            </div>
          </div>
          <h1 className="display-4 mb-3">Get your studies back in order.</h1>
          <p className="lead">
            Studious is a digital student planner for university students to
            stay on top of their studies.
          </p>
          <div className="d-flex">
            <div className="dropdown mr-3">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
              >
                View Studious
              </a>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="/today">
                  Today
                </a>
                <a className="dropdown-item" href="/subject">
                  Subjects
                </a>
                <a className="dropdown-item" href="/calendar">
                  Calendar
                </a>
              </div>
            </div>
            <div className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
              >
                View Subjects
              </a>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="/notes">
                  Notes
                </a>
                <a className="dropdown-item" href="/assignments">
                  Assignments
                </a>
                <a className="dropdown-item" href="/exams">
                  Exams
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="py-4 py-lg-5">
          <div
            className="carousel slide carousel-fade mb-5"
            data-ride="carousel"
          >
            <div className="carousel-inner overflow-visible">
              <div className="carousel-item shadow-lg active">
                <a href="pages-app.html">
                  <img
                    alt="Image"
                    src={navSideTeam}
                    className="img-fluid rounded border"
                  />
                </a>
              </div>
              <div className="carousel-item shadow-lg">
                <a href="pages-app.html">
                  <img
                    alt="Image"
                    src={navSideProject}
                    className="img-fluid rounded border"
                  />
                </a>
              </div>
              <div className="carousel-item shadow-lg">
                <a href="pages-app.html">
                  <img
                    alt="Image"
                    src={navSideTask}
                    className="img-fluid rounded border"
                  />
                </a>
              </div>
              <div className="carousel-item shadow-lg">
                <a href="pages-app.html">
                  <img
                    alt="Image"
                    src={navSideKanbanBoard}
                    className="img-fluid rounded border"
                  />
                </a>
              </div>
              <div className="carousel-item shadow-lg">
                <a href="pages-app.html">
                  <img
                    alt="Image"
                    src={navSideChat}
                    className="img-fluid rounded border"
                  />
                </a>
              </div>
              <div className="carousel-item shadow-lg">
                <a href="pages-app.html">
                  <img
                    alt="Image"
                    src={navSideUser}
                    className="img-fluid rounded border"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <i className="material-icons text-primary mr-3 h1">assignment</i>
            <h2 className="h1 mb-0">Put your subjects first</h2>
          </div>
          <p className="lead">
            Studious works around the subjects you're studying now - there is a
            single place for everything you need to do for each subject.
          </p>
          <p>
            Keep on top of your assessments and exams, never losing track of
            what you need to do and when you need to do it. Record all your
            results so you can review your progress. Write notes and upload
            course documents right into Studious.
          </p>
          <a href="/today" className="mr-3">
            Explore today
          </a>
          <a href="/subject">Explore subject</a>
        </section>
        <section className="py-4 py-lg-5">
          <div className="row mb-5">
            <div
              className="carousel slide carousel-fade col-6"
              data-ride="carousel"
            >
              <div className="carousel-inner overflow-visible">
                <div className="carousel-item shadow-lg active">
                  <img
                    alt="Image"
                    src={activity}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={avatar}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={avatarList}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={cardKanban}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={cardNote}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={cardProject}
                    className="img-fluid rounded border"
                  />
                </div>
              </div>
            </div>
            <div
              className="carousel slide carousel-fade col-6"
              data-ride="carousel"
            >
              <div className="carousel-inner overflow-visible">
                <div className="carousel-item shadow-lg active">
                  <img
                    alt="Image"
                    src={cardTeam}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={cardTask}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={cardList}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={chatItem}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={chatModule}
                    className="img-fluid rounded border"
                  />
                </div>
                <div className="carousel-item shadow-lg">
                  <img
                    alt="Image"
                    src={checklist}
                    className="img-fluid rounded border"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <i className="material-icons text-primary mr-3 h1">group_work</i>
            <h2 className="h1 mb-0">Private first, social second</h2>
          </div>
          <p className="lead">
            Use Studious to manage yourself and everything is private and only
            for you. Otherwise, invite your classmates and collaborate on group
            projects or more.
          </p>
          <p>
            When you're in a shared class you can share notes, study cards and
            more. Assessment and exam information is public. You can share and
            compare timetables to see what your classmates are up to.
          </p>
          <a href="/notes" className="mr-3">
            Explore notes
          </a>
          <a href="/calendar" className="mr-3">
            Explore calendar
          </a>
        </section>
        <section className="py-4 py-lg-5">
          <div className="row mb-5">
            <div className="col-4">
              <img
                alt="Image"
                src={layoutNavSide}
                className="shadow-lg img-fluid"
              />
            </div>
            <div className="col-4">
              <img
                alt="Image"
                src={layoutNavTop}
                className="shadow-lg img-fluid"
              />
            </div>
            <div className="col-4">
              <img
                alt="Image"
                src={layoutNavTopSidebar}
                className="shadow-lg img-fluid"
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <i className="material-icons text-primary mr-3 h1">trending_up</i>
            <h2 className="h1 mb-0">Monitor your progress</h2>
          </div>
          <p className="lead">
            Upload results for all markable assessments or exams right in
            Studious to see what rank you're on track for.
          </p>
          <p>
            Studious can tell you what results you need to achieve in upcoming
            assessments in order to hit the grades you're after.
          </p>
          <a href="/assessments" className="mr-3">
            Explore assessments
          </a>
          <a href="/exams" className="mr-3">
            Explore exams
          </a>
          <a href="/subject">Explore subject</a>
        </section>
      </div>
    </div>
  </div>
);
